import React from "react";
import {Grid,Avatar,Typography,Box} from "@mui/material";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import slackMarkdownToMarkdown from "./slackMarkDown/slackMarkdownToMarkdown";

export default React.memo(function MyMessage({messageBody}){
    const {
        text,
        isNext,
        user,
        avatar,
        ts_formatted,
        date,
    } = messageBody;
    return <Grid container px={2} py={1} spacing={1}>
        {!isNext
            ?<Grid item container alignItems={'center'} justifyContent={'flex-end'} gap={1} xs={12}>
                <Box>
                    <Typography sx={{color:'text.disabled',fontSize:12,display:'inline'}}>{ts_formatted}</Typography>
                    <Typography sx={{fontSize:14,fontWeight:700,display:'inline',ml:1}}>{user}</Typography>
                </Box>
                <Avatar src={avatar} sx={{width:30,height:30,fontSize:18}}>{user&&user[0]}</Avatar>
            </Grid>
            :''
        }
        <Grid item xs={12}>
            <Box className={"slack-mdk"} sx={(theme)=>({
                color:'primary.contrastText',
                backgroundColor:'primary.main',
                ml:'50px',
                width:`calc(100% - 50px)`,
                px:3,
                py:2,
                borderRadius:4,
                borderTopRightRadius:0,
                fontSize:14,
                wordBreak:'break-word',
                position:'relative'
            })}>
                <Markdown remarkPlugins={[remarkGfm]}>{slackMarkdownToMarkdown(text)}</Markdown>
                <Typography 
                    sx={{
                        position:'absolute',
                        fontSize:10,
                        right:16
                    }}
                >
                    {date}
                </Typography>
            </Box>
        </Grid>
    </Grid>
}
)