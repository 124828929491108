import React, {useCallback, useEffect, useState} from "react";
import {
    Button,
    Dialog, DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    CircularProgress,
    FormHelperText
} from "@mui/material";
import {DatePicker} from "@mui/x-date-pickers";
import moment from "moment";
import {PRIORITIES, STATUSES, TYPES, getLabel, PriorityIcon,DECLINE_REASON} from "./options";
import { updateTicket} from "./ticketsApi";
import {useSnackbar} from "notistack";

export default function EditDialog({ticket,open,onClose,setEditedTicket,forceStatus}){
    const [loading,setLoading]= useState(false);
    const {enqueueSnackbar} = useSnackbar();
    const [ticketValues,setTicketValues] = useState({
        status:'',
        // priority:'',
        // type:'',
        // rating:'',
        // due_date:null,
        // description:'',
        decline_reason:'',
        decline_reason_description:'',
    });
    const [hideDecline,setHideDecline] = useState({
        reason:true,
        description:true
    });
    const [errors,setErrors] = useState({
        declineReason:false,
        declineReasonDescription:false,
    });
    const handleChange = useCallback((field,isDate)=>(e)=>{
        setTicketValues(prevState => ({...prevState,[field]:isDate?e:e.target.value}))
    },[]);
    useEffect(() => {
        setTicketValues({
            status:forceStatus||ticket.status||'',
            // priority:ticket.priority||'',
            // type:ticket.type||'',
            // rating:ticket.rating||'',
            // due_date:moment(ticket.due_date),
            // description:ticket.description||'',
            decline_reason:ticket.decline_reason||'',
            decline_reason_description:ticket.decline_reason_description||'',
            
        });
    }, [ticket]);
    const handleSave = useCallback(()=>{
        
        const declineReasonError = !hideDecline.reason&&!ticketValues.decline_reason;
        const declineDescriptionError = !hideDecline.description&&!ticketValues.decline_reason_description;
        setErrors(prevState => ({
            declineReason:declineReasonError,
            declineReasonDescription: declineDescriptionError,
        }));
        if(declineReasonError||declineDescriptionError){
            return;
        }
        const requestData = {
            status:ticketValues.status
        };
        if(ticketValues.status==='declined'){
            requestData.decline_reason = ticketValues.decline_reason;
            if(requestData.decline_reason === 'already_exists'){
                requestData.decline_reason_description = ticketValues.decline_reason_description;
            }
        }
        
        setLoading(true);
        
        updateTicket(ticket.id,requestData)
            .then(response=>{
                setEditedTicket(response.data,ticket);
                onClose();
                enqueueSnackbar('Success!',{variant:'success'});
            })
            .catch(e=> {
                console.log(e)
                enqueueSnackbar(e.message,{variant:'error'});
            })
            .finally(()=>{
                setLoading(false);
            });
    },[ticketValues,ticket,hideDecline]);
    useEffect(() => {
        setHideDecline(prevState => ({
            ...prevState,
            reason:ticketValues.status!=='declined',
            description:ticketValues.status!=='declined' || ticketValues.decline_reason!=='already_exists',
        }));
    }, [ticketValues.status,ticketValues.decline_reason]);
    return <Dialog
        open={open}
        onClose={onClose}
    >
        <DialogTitle>
            Edit ticket {ticket.id}
        </DialogTitle>
        <DialogContent sx={{width:550,maxWidth:'100%'}}>
            <Grid container py={2} spacing={2}>
                {/*<Grid item xs={12}>*/}
                {/*    <FormControl fullWidth>*/}
                {/*        <DatePicker */}
                {/*            label={"Due date"} */}
                {/*            value={ticketValues.due_date}*/}
                {/*            format={"DD-MM-YYYY"}*/}
                {/*            onChange={handleChange('due_date',true)}*/}
                {/*        />*/}
                {/*    </FormControl>*/}
                {/*</Grid>*/}
                <Grid item xs={12}>
                    <FormControl size={"small"} fullWidth>
                        <InputLabel id="status-select-label">Status</InputLabel>
                        <Select
                            labelId="status-select-label"
                            id="status-select"
                            label="Status"
                            value={ticketValues.status}
                            onChange={handleChange('status')}
                        >
                            {
                                STATUSES.map((status)=><MenuItem key={status} value={status}>
                                    {getLabel(status)}
                                </MenuItem>)
                            }
                        </Select>
                    </FormControl>
                </Grid>
                {/*<Grid item xs={12}>*/}
                {/*    <FormControl size={"small"} fullWidth>*/}
                {/*        <InputLabel id="priority-select-label">Priority</InputLabel>*/}
                {/*        <Select*/}
                {/*            labelId="priority-select-label"*/}
                {/*            id="priority-select"*/}
                {/*            label="Priority"*/}
                {/*            value={ticketValues.priority}*/}
                {/*            onChange={handleChange('priority')}*/}
                {/*        >*/}
                {/*            {*/}
                {/*                PRIORITIES.map(priority=><MenuItem key={priority} value={priority}>*/}
                {/*                    <PriorityIcon priority={priority}/> */}
                {/*                    {getLabel(priority)}*/}
                {/*                </MenuItem>)*/}
                {/*            }*/}
                {/*        </Select>*/}
                {/*    </FormControl>*/}
                {/*</Grid>*/}
                {/*<Grid item xs={12}>*/}
                {/*    <FormControl size={"small"} fullWidth>*/}
                {/*        <InputLabel id="type-select-label">Status</InputLabel>*/}
                {/*        <Select*/}
                {/*            labelId="type-select-label"*/}
                {/*            id="type-select"*/}
                {/*            label="Status"*/}
                {/*            value={ticketValues.type}*/}
                {/*            onChange={handleChange('type')}*/}
                {/*        >*/}
                {/*            {*/}
                {/*                TYPES.map((type)=><MenuItem key={type} value={type}>*/}
                {/*                    {getLabel(type)}*/}
                {/*                </MenuItem>)*/}
                {/*            }*/}
                {/*        </Select>*/}
                {/*    </FormControl>*/}
                {/*</Grid>*/}
                {/*<Grid item xs={12}>*/}
                {/*    <TextField*/}
                {/*        label={"Rating"}*/}
                {/*        value={ticketValues.rating}*/}
                {/*        type={'number'}*/}
                {/*        onChange={handleChange('rating')}*/}
                {/*        fullWidth*/}
                {/*    />*/}
                {/*</Grid>*/}
                {/*<Grid item xs={12}>*/}
                {/*    <TextField */}
                {/*        multiline */}
                {/*        minRows={3}*/}
                {/*        label={"Description"}*/}
                {/*        value={ticketValues.description}*/}
                {/*        onChange={handleChange('description')}*/}
                {/*        fullWidth */}
                {/*    />*/}
                {/*</Grid>*/}
                <Grid item xs={12} hidden={hideDecline.reason}>
                    <FormControl error={errors.declineReason} size={"small"} fullWidth>
                        <InputLabel  id="decline_reason-select-label">Decline reason</InputLabel>
                        <Select
                            labelId="decline_reason-select-label"
                            id="decline_reason-select"
                            label="Decline reason"
                            value={ticketValues.decline_reason}
                            onChange={handleChange('decline_reason')}
                        >
                            {
                                DECLINE_REASON.map(reason=><MenuItem key={reason} value={reason}>
                                    {getLabel(reason)}
                                </MenuItem>)
                            }
                        </Select>
                        {errors.declineReason&&<FormHelperText>Field is required!</FormHelperText>}
                    </FormControl>
                </Grid>
                <Grid item xs={12} hidden={hideDecline.description}>
                    <TextField
                        multiline
                        error={errors.declineReasonDescription}
                        minRows={3}
                        label={"Decline reason description"}
                        value={ticketValues.decline_reason_description}
                        onChange={handleChange('decline_reason_description')}
                        fullWidth
                    />
                    {errors.declineReasonDescription&&<FormHelperText error>Field is required!</FormHelperText>}
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button color={"error"} onClick={onClose}>
                Close
            </Button>
            <Button color={"success"} disabled={loading}  onClick={handleSave}>
                {loading?<CircularProgress size={24}/>:'Save'}
            </Button>
        </DialogActions>
    </Dialog>}