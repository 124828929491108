import data from "@emoji-mart/data";

export default function slackMarkdownToMarkdown(slackMarkdown) {
    let markdown = slackMarkdown;

    const codeBlockPlaceholder = [];
    const emojiPlaceholder = [];

    markdown = markdown.replace(/```([\s\S]+?)```/g, (match) => {
        codeBlockPlaceholder.push(match.replace(/```([\s\S]+?)```/g, '```\n$1\n```')); // Store the full code block
        return `$$codeBlockPlaceholder$${codeBlockPlaceholder.length - 1}$$`;
    });

    // Emoji
    markdown = markdown.replace(/:(\+1|[-\w]+):(:skin-tone-(\d):)?/g, (match,$1,$2,$3) => {
        
        if(data.emojis.hasOwnProperty($1)){
            const tone = $3?$3-1:0
            return data.emojis[$1].skins[tone].native
        }
        emojiPlaceholder.push(match); // Store unhandled emoji the emoji
        return `$$emojiPlaceholder$${emojiPlaceholder.length - 1}$$`;
    });
    // Convert Slack bold (*bold*) to Markdown bold (**bold**)
    markdown = markdown.replace(/\*(.*?)\*/g, '**$1**');

    // Convert Slack italics (_italic_) to Markdown italics (*italic*)
    markdown = markdown.replace(/_(.*?)_/g, '*$1*');

    // Convert Slack strikethrough (~strikethrough~) to Markdown strikethrough (~~strikethrough~~)
    markdown = markdown.replace(/~(.*?)~/g, '~~$1~~');

    // Convert Slack blockquotes (&gt;) to Markdown blockquotes (>)
    markdown = markdown.replace(/^&gt;\s?/gm, '> ');

    // Convert Slack inline code (`inline code`) remains the same in Markdown
    // No changes required for this

    // Convert Slack code blocks (```code```) to Markdown code blocks (```code```)
    // markdown = markdown.replace(/```([\s\S]+?)```/g, '```\n$1\n```');

    // Convert Slack unordered lists (• or -) to Markdown lists (-)
    markdown = markdown.replace(/^•\s/gm, '- ');
    markdown = markdown.replace(/^\-\s/gm, '- ');
    // Reflect `+ ` to unicode to prevent treat it as list item
    markdown = markdown.replace(/^\+\s/gm, '&#43; ');

    // Convert Slack links (<http://example.com|Link Text>) to Markdown links [Link Text](http://example.com)
    markdown = markdown.replace(/<([^|]+)\|([^>]+)>/g, '[$2]($1)');

    // Decode HTML entities like &lt;, &gt;, &amp;
    markdown = markdown.replace(/&lt;/g, '<');
    markdown = markdown.replace(/&gt;/g, '>');
    markdown = markdown.replace(/&amp;/g, '&');

    // Handle Slack emojis by removing or replacing with a placeholder (you can replace this with actual emojis if desired)
    // markdown = markdown.replace(/:([a-zA-Z0-9_+-]+):/g, ''); // Removes the emoji completely

    // Handle Slack @mentions (e.g., <@U12345|username>) and #channel (e.g., <#C12345|channel-name>)
    // markdown = markdown.replace(/<@([A-Z0-9]+)>/g, '@$1'); // Converts <@U12345|username> to @username
    // markdown = markdown.replace(/<#[A-Z0-9]+>/g, '#$1');    // Converts <#C12345|channel-name> to #channel-name

    markdown = markdown.replace(/<@([A-Z0-9]+)>/g, '@$1'); // Converts <@U12345> to @user

    // Handle Slack #channel references (e.g., <#C12345|channel-name>)
    markdown = markdown.replace(/<#([A-Z0-9]+)\|([^>]+)?>/g, '#$1');
    // Handle line breaks
    markdown = markdown.replace(/\n/g, `  \n`);

    markdown = markdown.replace(/\$\$codeBlockPlaceholder\$(\d+)\$\$/g, (match, p1) => {
        return codeBlockPlaceholder[p1];
    });
    
    // 15. Restore emojis
    markdown = markdown.replace(/\$\$emojiPlaceholder\$(\d+)\$\$/g, (match, p1) => {
        return emojiPlaceholder[p1];
    });
    
    return markdown;
}