import React from "react";
import {Grid,Avatar,Typography,Box} from "@mui/material";
import remarkGfm from "remark-gfm";
import slackMarkdownToMarkdown from "./slackMarkDown/slackMarkdownToMarkdown";
import Markdown from "react-markdown";

export default function AskMessage({messageBody,}){
    const {
        text,
        user,
        ts_formatted,
        avatar,
        isNext,
        date,
    } = messageBody;
    return <Grid container px={2} py={1} spacing={1}>
        {!isNext?<Grid item container alignItems={'center'} gap={1} xs={12}>
            <Avatar src={avatar} sx={{width: 30, height: 30, fontSize: 18}}>{user && user[0]}</Avatar>
            <Box>
                <Typography sx={{fontSize:14,fontWeight:700,display:'inline'}}>{user}</Typography>
                <Typography sx={{color:'text.disabled',fontSize:12,display:'inline',ml:1}}>{ts_formatted}</Typography>
            </Box>
        </Grid>:''}
        <Grid item xs={12}>
            <Box 
                sx={(theme)=>({
                    backgroundColor:theme.palette.mode==='dark'
                        ?theme.palette.grey[700]
                        :theme.palette.grey[100],
                    mr:'50px',
                    width:`calc(100% - 50px)`,
                    px:3,
                    py:2,
                    borderRadius:4,
                    borderTopLeftRadius:0,
                    fontSize:14,
                    wordBreak:'break-word',
                    position:'relative'
                })}
            >
                {/*backup variant*/}
                {/*<div className={"slack-mdk"} dangerouslySetInnerHTML={{__html:sanitizeHtml(*/}
                {/*        escapeForSlackWithMarkdown(*/}
                {/*            slackMarkdownToHtml(text)*/}
                {/*        ))*/}
                {/*}}/>*/}
                <Markdown remarkPlugins={[remarkGfm]}>{slackMarkdownToMarkdown(text)}</Markdown>
                <Typography
                    sx={{
                        position:'absolute',
                        fontSize:10,
                        right:16
                    }}
                >
                    {date}
                </Typography>
            </Box>
        </Grid>
    </Grid>
}